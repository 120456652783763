import React, { useContext } from 'react';
import * as S from './DelayCalendar.styles';
import ReactCalendar from 'react-calendar';
import { addMonthsToDate } from '../../utils/addMonthsToDate';
import FunnelContext from '../../context/FunnelContext';
import { addDaysToDate } from '../../utils/addDaysToDate';

const DelayCalendar = ({
  locale,
  calendarType,
  showNeighboringMonth,
  prev2Label,
  next2Label,
  minDetail,
  maxDetail,
  minDate,
  maxDate
}) => {
  const { deliveryDate, calendarDate, setCalendarDate } = useContext(
    FunnelContext
  );
  let date;

  if (deliveryDate) {
    date = new Date(deliveryDate);
  } else {
    date = new Date();
  }

  return (
    <S.CalendarWrapper>
      <ReactCalendar
        locale={locale}
        calendarType={calendarType}
        className="mt-8 w-max mx-auto border-opacity-0"
        showNeighboringMonth={showNeighboringMonth}
        prev2Label={prev2Label ? '«' : null}
        next2Label={next2Label ? '»' : null}
        minDetail={minDetail}
        maxDetail={maxDetail}
        minDate={minDate || addDaysToDate(date, 1)}
        maxDate={maxDate || addMonthsToDate(date, 1)}
        value={new Date(calendarDate)}
        onChange={value => {
          const dateOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          };
          setCalendarDate(value.toLocaleDateString('en-US', dateOptions));
        }}
      />
    </S.CalendarWrapper>
  );
};

export default DelayCalendar;
