import styled from 'styled-components';

export const CalendarWrapper = styled.div`
  .react-calendar {
    max-width: 100%;
    border: 1px solid #ccd2e3;
    font-family: 'Neuzeit Grotesk', sans-serif;
    line-height: 1.125em;
    border-radius: 0.25rem;
    padding: 1.5rem;

    @media (max-width: 500px) {
      padding: 1rem;
    }
  }
  .react-calendar__navigation__label__labelText {
    font-family: 'Utopia Std', serif;
    font-style: italic;
    font-weight: 400;
    font-size: 25px;
    line-height: 120%;
    color: #001c72;

    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    font-size: 30px;
    background: none;
    color: #001c72;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled]:first-child,
  .react-calendar__navigation button[disabled]:last-child {
    color: #001c72;
    opacity: 0.5;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: capitalize;
    font-weight: normal;
    text-decoration: none;
    padding-bottom: 1em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.25em 0.5em;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 140%;
    text-decoration: none;
    color: #6677aa;

    abbr {
      text-decoration: none;
    }
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day {
    color: #001c72;
    font-weight: 700;
    font-size: 15px;
    line-height: 140%;
  }
  .react-calendar__month-view__days__day--weekend {
    // color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.5em;
    background: none;

    abbr {
      display: inline-block;
      width: 100%;
      height: 30px;
      line-height: 30px;
    }
  }
  .react-calendar__tile:disabled {
    // more styling for the case of a disabled tile would go here
    cursor: unset;
    abbr {
      // or here
      color: #dcdcdc;
    }
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    abbr {
      background: #f2d5d8;
    }
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    abbr {
      background: #001c72;
      color: white;
    }
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    abbr {
      background: #001c72;
    }
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
`;
